
import { mapGetters } from 'vuex'
export default {
  data() {
    return{
      showSkeleton: true, // skeleton is shown initially
    }
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      regionReady: 'geolocation/isRegionReady',
      userRegion: 'user/region',
      currentLabel: 'cart/getPriceLabel',
      detectedCountry: 'geolocation/detectedCountry',
    }),
    activeFlag(){
      const fl = this.$nuxt.$options.filters.flagFromLabel(this.currentLabel,this.detectedCountry)
      console.log('HEADER FLAG',fl)
      return fl
    },
    flagLink(){
      const fl = this.activeFlag
      if(fl === 'fi-gb'){
        return ''
      } else if(fl === 'fi-ie'){
        return '/ie'
      } else if(fl === 'fi-au'){
        return '/au'
      } else if(fl === 'fi-ca'){
        return '/ca'
      } else if(fl === 'fi-eu'){
        return '/ie'
      } else if(fl === 'fi-za'){
        return '/za'
      } else {
        return '/en-us'
      }
    },
    region(){
      console.log('MENU REGIONS',this.userRegion, this.detectedRegion)
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    },
    regionVar(){
      console.log('COMPUTING REGION FOR MENU TO SHOW...',this.$props.region, this.detectedRegion, this.userRegion)
      const regionVar = this.$props.region
      if (regionVar === 'ie' || regionVar === 'ca' || regionVar === 'au' || regionVar === 'za' || regionVar === 'en-us') {
        return '/'+regionVar
      }
      if(regionVar === 'eu'){
        return '/ie'
      }
      return ''
    }
  },
  mounted() {
      this.showSkeleton = false; // hide skeleton immediately on client mount
  },
}
