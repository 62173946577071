function parseCookies(cookieString = '') {
    if (!cookieString) return {};
    return cookieString.split(';').reduce((acc, cookie) => {
        const parts = cookie.split('=');
        if (parts.length < 2) return acc;
        const key = parts[0].trim();
        const value = decodeURIComponent(parts.slice(1).join('=').trim());
        if (key) acc[key] = value;
        return acc;
    }, {});
}

export const actions = {
    nuxtServerInit({ commit }, { req }) {

        if (!req || !req.headers || !req.headers.cookie) return;

        const cookies = parseCookies(req.headers.cookie);

        console.log('ServerInit::', cookies);

        // ✅ 1. Set user-defined region if available
        if (cookies.tefl_user_region) {
            commit('user/setRegion', cookies.tefl_user_region);
            console.log('ServerInit: user region from cookie:', cookies.tefl_user_region);
        }

        // ✅ 2. Always hydrate geolocation too (might still be useful for timezone, etc.)
        const geo = {
            detectedCountry: cookies.tefl_geolocation_country || '',
            detectedCity: cookies.tefl_geolocation_city || '',
            detectedRegion: cookies.tefl_geolocation_region || '',
            detectedCurrency: cookies.tefl_geolocation_currency || '',
            detectedTimezone: cookies.tefl_geolocation_timezone || ''
        };

        commit('geolocation/SET_GEOLOCATION', geo);
        commit('geolocation/SET_DEFAULT', true);
        commit('geolocation/SET_REGION_READY', true);
    }
}
