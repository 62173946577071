function getMappedRegion(regionCode) {
    switch (regionCode) {
        case 'TEFLORG': return { region: 'en', currency: 'GBP' };
        case 'IRELAND': return { region: 'ie', currency: 'EUR' };
        case 'CANADA': return { region: 'ca', currency: 'CAD' };
        case 'AUSTRALIA': return { region: 'au', currency: 'AUD' };
        case 'SOUTHAFRICA': return { region: 'za', currency: 'ZAR' };
        case 'EU': return { region: 'ie', currency: 'EUR' };
        default: return { region: 'en-us', currency: 'USD' };
    }
}

export default ({ store, app }, inject) => {
    inject('setUserRegion', (regionCode) => {
        const { region, currency } = getMappedRegion(regionCode);

        // Update store
        store.dispatch('user/updateRegion', region);
        store.dispatch('cart/changeCurrencyServer', regionCode);

        // Set cookie
        app.$cookies.set('tefl_user_region', region, {
            path: '/',
            maxAge: 60 * 60 * 24 * 365
        });

        console.log(`Region switched to: ${region} | Cookie set`);
    });
}
