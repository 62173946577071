export default ({ store, app }) => {
  console.log('Geolocate plugin init:', process.static, process.server);

  const g = {
      "GBP": 'TEFLORG',
      "USD": "TEFLUS",
      "EUR": "IRELAND",
      "CAD": "CANADA",
      "AUD": "AUSTRALIA",
      "ZAR": "SOUTHAFRICA"
  };

  const defaultSaved = store.state.geolocation.defaultSaved;

  console.log('Store GEO Timezone:', store.state.geolocation.detectedTimezone);
  console.log('GEO defaultSaved:', defaultSaved);

  // Populate geolocation from cookies if available
  const timezone = app.$cookies.get('tefl_geolocation_timezone') || '';
  const city = app.$cookies.get('tefl_geolocation_city') || '';
  const country = app.$cookies.get('tefl_geolocation_country') || '';
  const region = app.$cookies.get('tefl_geolocation_region') || '';
  const currency = app.$cookies.get('tefl_geolocation_currency') || '';

  // Only update store if not yet saved
  if (!defaultSaved) {
      console.log('Setting geo from cookies');

      store.commit("geolocation/SET_GEOLOCATION", {
          detectedTimezone: timezone,
          detectedCity: city,
          detectedCountry: country,
          detectedRegion: region,
          detectedCurrency: currency,
      });

      const priceLabel = g[currency] || 'TEFLORG';
      store.commit("cart/setPriceLabel", priceLabel);
      store.commit("geolocation/SET_DEFAULT", true);
  }

  // ALWAYS set region ready
  // This MUST happen to prevent hydration issues
  store.commit("geolocation/SET_REGION_READY", true);
  console.log('SET_REGION_READY: true');
}
