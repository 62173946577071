
import { mapGetters, mapActions } from 'vuex'

import bannerData from '../../data/banner.json'

// convert local time to another timezone


export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
      isOpen: false,
      isSearch:false,
      coursesSub: false,
      jobsSub: false,
      resourcesSub: false,
      onlineSub: false,
      level5Sub: false,
      virtualSub: false,
      classroomSub: false,
      advancedSub: false,
      newToTeflSub: false,
      teoSub: false,
      teaSub: false,
      aboutUsSub: false,
      tzz:''
      // phoneNumber: '0330 808 9138',

    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      label: 'cart/getPriceLabel',
      cart : 'cart/cartProducts',
      totals : 'cart/getTotals',
      timeZone: 'geolocation/detectedTimezone',
      getHours: 'geolocation/getHours',
      detectedRegion: 'geolocation/region',
      detectedCountry: 'geolocation/detectedCountry',
      userRegion: 'user/region',
      activeModal : 'pages/getActiveModal',
    }),
    regionVar(){
      console.log('COMPUTING REGION FOR MENU TO SHOW...',this.$props.region, this.detectedRegion, this.userRegion)
      const regionVar = this.$props.region
      if (regionVar === 'ca' || regionVar === 'au' || regionVar === 'za') {
        return '/'+regionVar
      }
      return '/en-us'
    },
    flag() {
      const fl = this.$nuxt.$options.filters.flagFromLabel(this.label,this.detectedCountry)
      return fl
    },
    currencyCode() {
      const cc = this.$nuxt.$options.filters.currencyCodeFromLabel(this.label)
      return cc
    },
    banner() {
      return bannerData
    },

    itemsInCart(){
     //  const cart = this.$store.getters.cart.cartProducts;
      return this.cart.length
    },
    cartTotal(){
      return this.totals.discounted
    },

    // BANNER STUFF
    bgColour(){
      return this.banner.banner_background_color_us
    },
    buttonColour(){
      return this.banner.banner_button_primary_color_us
    },
    offerLink(){
      return this.banner.offer_url_us_bf
    },
    offerText(){
      return this.banner.offer_text_us_bf
    },
    offerAmount(){
      return this.banner.discount_amount_us_bf
    },

  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty('overflow', 'hidden')
          else document.body.style.removeProperty('overflow')
        }
      },
    }
  },


  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27 && this.isOpen) this.isOpen = false
    })
  },

  methods: {
    ...mapActions({
      addToCart:'cart/addToCartServer',
      resetCart:'cart/resetCart',
      setHours: 'geolocation/setHours',
      updateActiveModal: 'pages/updateActiveModal',
    }),
    updateModal(id){
        this.updateActiveModal(id)
        console.log('Active Modal: ',this.activeModal)
      },


    showCurrency() {
      this.showModal = true
    },

    drawer() {
      this.isOpen = !this.isOpen
    },

    coursesToggle() {
      this.coursesSub = !this.coursesSub
    },
    newToTeflToggle() {
      this.newToTeflSub = !this.newToTeflSub
    },
    teoToggle(){
      this.teoSub = !this.teoSub
    },
    teaToggle(){
      this.teaSub = !this.teaSub
    },
    jobsToggle() {
      this.jobsSub = !this.jobsSub
    },
    resourcesToggle() {
      this.resourcesSub = !this.resourcesSub
    },
    aboutUsToggle() {
      this.aboutUsSub = !this.aboutUsSub
    },

    onlineToggle() {
      this.level5Sub = false
      this.virtualSub = false
      this.classroomSub = false
      this.advancedSub = false
      this.onlineSub = !this.onlineSub
    },

    level5Toggle() {
      this.onlineSub = false
      this.virtualSub = false
      this.classroomSub = false
      this.advancedSub = false
      this.level5Sub = !this.level5Sub
    },

    virtualToggle() {
      this.onlineSub = false
      this.level5Sub = false
      this.classroomSub = false
      this.advancedSub = false
      this.virtualSub = !this.virtualSub
    },

    classroomToggle() {
      this.onlineSub = false
      this.level5Sub = false
      this.virtualSub = false
      this.advancedSub = false
      this.classroomSub = !this.classroomSub
    },

    advancedToggle() {
      this.onlineSub = false
      this.level5Sub = false
      this.virtualSub = false
      this.classroomSub = false
      this.advancedSub = !this.advancedSub
    },

    async logout() {
      await this.$store.dispatch('user/logout')
      // await this.$store.dispatch('cart/fullReset')
      this.$router.push({ path: '/my-account' })
    },
  },
}
