export default (context, inject) => {
    const cookies = {
        get(name) {
            if (typeof document === 'undefined') return null;
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
            return null;
        },
        set(name, value, options = {}) {
            if (typeof document === 'undefined') return;
            const opt = Object.assign({ path: '/', maxAge: 60 * 60 * 24 * 365 }, options); // default 1 year
            let cookieString = `${name}=${encodeURIComponent(value)}`;
            if (opt.maxAge) cookieString += `; max-age=${opt.maxAge}`;
            if (opt.path) cookieString += `; path=${opt.path}`;
            document.cookie = cookieString;
        },
        remove(name) {
            if (typeof document === 'undefined') return;
            document.cookie = `${name}=; Max-Age=0; path=/`;
        }
    };

    inject('cookies', cookies);
}
