import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=cf9abd36&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=cf9abd36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf9abd36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIOpeningTimes: require('/opt/build/repo/components/UI/OpeningTimes.vue').default,UIHeaderUK: require('/opt/build/repo/components/UI/HeaderUK.vue').default,UIHeaderUS: require('/opt/build/repo/components/UI/HeaderUS.vue').default,UICurrencySelect: require('/opt/build/repo/components/UI/CurrencySelect.vue').default})
