
import moment from 'moment'
import { mapGetters } from 'vuex'
import bannerData from '@/data/banner.json'
export default {
  data: () => ({
    time: {Days:"0",Hours:"0",Minutes:"0",Seconds:"0"},
    zeroTime: {Days:"00",Hours:"00",Minutes:"00",Seconds:"00"},
    i: 0,
    ended:false,
    // trackers: ['Days', 'Hours','Minutes','Seconds'] // 'Random', 
    sale_days: {
      uk: [1, 4],  // Monday and Thursday
      us: [2, 5]   // Tuesday and Friday
    }
  }),

  computed: {
    banner(){
      return bannerData
    },
    bgColour(){
      return this.region === 'en-us' ? this.banner.banner_background_color_us : this.banner.banner_background_color
    },
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    region(){
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    }
  },
  
  watch: {
    'date'(newVal){
      this.setCountdown(newVal);
    }
  },

  mounted() {

    const todayDate = moment();
    const nextSaleDate = this.getNextSaleDate();
    
    if (todayDate.isAfter(nextSaleDate)) {
      this.ended = true;
    } else {
      this.setCountdown();
      this.update();
    }

  },
  
  methods: {

    getNextSaleDate() {
      const today = moment();
      const isUS = this.region === 'en-us';
      const saleDays = isUS ? this.sale_days.us : this.sale_days.uk;
      const timeStr = isUS ? '09:00:00' : '23:59:59';

      console.log('Region:', this.region);
      console.log('Using sale days:', saleDays);

      // Create full date-time objects with the correct times
      const date1 = moment().day(saleDays[0]).format('YYYY-MM-DD ') + timeStr;
      const date2 = moment().day(saleDays[1]).format('YYYY-MM-DD ') + timeStr;
      
      const date1Moment = moment(date1, 'YYYY-MM-DD HH:mm:ss');
      const date2Moment = moment(date2, 'YYYY-MM-DD HH:mm:ss');

      console.log('Current time:', today.format('YYYY-MM-DD HH:mm:ss'));
      console.log('First sale date:', date1);
      console.log('Second sale date:', date2);
      
      // If we're past both dates this week, move to next week
      if (today.isAfter(date2Moment)) {
          console.log('Past both dates, moving to next week');
          date1Moment.add(1, 'week');
          date2Moment.add(1, 'week');
          console.log('New dates:', {
              date1: date1Moment.format('YYYY-MM-DD HH:mm:ss'),
              date2: date2Moment.format('YYYY-MM-DD HH:mm:ss')
          });
      }
      
      // If we're past the first date but before second, use second date
      if (today.isAfter(date1Moment) && today.isBefore(date2Moment)) {
          console.log('Between dates, using second date');
          return date2Moment;
      }
      
      console.log('Using first date');
      return date1Moment;
  },

  setCountdown(date) {
      const isUS = this.region === 'en-us';
      const timeStr = isUS ? '09:00:00' : '23:59:59';
      
      console.log('Setting countdown:', {
          isUS,
          timeStr,
          hasDate: !!date
      });
      
      if (date) {
          this.countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
      } else {
          const nextDate = this.getNextSaleDate();
          const dateTimeStr = nextDate.format('YYYY-MM-DD ') + timeStr;
          this.countdown = moment(dateTimeStr, 'YYYY-MM-DD HH:mm:ss');
          console.log('Final countdown time:', this.countdown.format('YYYY-MM-DD HH:mm:ss'));
      }
  },
    
    update() { 
      const time = {Days:"00",Hours:"00",Minutes:"00",Seconds:"00"}
      this.frame = window.requestAnimationFrame(this.update.bind(this));
      if ( this.i++ % 10 ) { return; }
      let t = moment(new Date());
      // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
      if ( this.countdown ) { 
        
        t = this.countdown.diff(t);

          // Add logging every minute (to avoid console spam)
          if (this.i % 600 === 0) { // 600 = 10 frames/sec * 60 seconds
              console.log('Time remaining:', {
                  total: t,
                  days: Math.floor(t / (1000 * 60 * 60 * 24)),
                  hours: Math.floor((t / (1000 * 60 * 60)) % 24),
                  minutes: Math.floor((t / 1000 / 60) % 60)
              });
          }

        time.Days = Math.floor(t / (1000 * 60 * 60 * 24));
        time.Hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        time.Minutes = Math.floor((t / 1000 / 60) % 60);
        time.Seconds = Math.floor((t / 1000) % 60);
      } else {
        time.Days = undefined;
        time.Hours = t.hours() % 13;
        time.Minutes = t.minutes();
        time.Seconds = t.seconds();
      }

      this.time = time
      return this.time;
    }
  }
}
