
import { mapGetters } from 'vuex'
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    regionVar(){
      console.log('COMPUTING REGION FOR MENU TO SHOW...',this.$props.region, this.detectedRegion, this.userRegion)
      const regionVar = this.$props.region
      if (regionVar === 'ie' || regionVar === 'ca' || regionVar === 'au' || regionVar === 'za') {
        return '/'+regionVar
      }
      if(regionVar === 'eu'){
        return '/ie'
      }
      return ''
    }
  },
}
